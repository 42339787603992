import {
  number,
  string,
  union,
  object,
  nullish,
  array,
  boolean,
  type Input,
  parse,
} from "valibot";
import qs from "qs";
import humps from "humps";
import useHttp from "~/composables/useHttp";
import { useHandleError } from "~/composables/useHandleError";

const payloadSchema = object({
  endPointApi: nullish(string()),
  cityId: union([number(), string()]),
  pageNumber: union([number(), string()]),
  pageSize: union([number(), string()]),
});

const attributesSchema = object({
  cover: object({ url: string() }),
  tagType: nullish(string()),
  title: string(),
  titleEn: string(),
  totalRestaurants: number(),
});

const linksSchema = object({
  first: string(),
  last: string(),
  next: string(),
  prev: nullish(string()),
  self: string(),
});

const singleDataSchema = object({
  attributes: attributesSchema,
  id: string(),
  type: string(),
});

const dataSchema = array(
  object({
    attributes: attributesSchema,
    id: string(),
    type: string(),
  })
);

const responseDataSchema = object({
  data: dataSchema,
  links: linksSchema,
  message: nullish(string()),
  success: boolean(),
});

export type RecommendedTag = Input<typeof singleDataSchema>;
type PayloadSchema = Input<typeof payloadSchema>;
export type RecommendedTagData = Input<typeof dataSchema>;
type ResponseData = Input<typeof responseDataSchema>;
export async function getRecommendedTag(payload: PayloadSchema) {
  try {
    const queryString = {
      cityId: payload.cityId,
      recommendedTag: true,
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
    };

    const parseQueryString = qs.stringify(humps.decamelizeKeys(queryString), {
      encode: false,
    });

    let finalUrl = `${payload.endPointApi}?${parseQueryString}`;
    if (!payload.endPointApi) {
      finalUrl = `/restaurant_tags.json?${parseQueryString}`;
    }
    const { data, error } = await useHttp({
      url: finalUrl,
      method: "GET",
    });

    if (error?.message) {
      const message = useHandleError({
        defaultErrorMessage: error.message,
        errorPath: "api/createBooking",
      });
      return {
        success: false,
        message,
        data: null,
      };
    }
    const parseData = parse(responseDataSchema, data);
    const { success, message } = parseData as ResponseData;
    return {
      success,
      message,
      data: parseData,
    };
  } catch (err) {
    const message = useHandleError({
      errorPath: "getRecommendedTag",
      err,
      defaultErrorMessage:
        "Oops, something went wrong, failed creating booking",
    });
    return {
      success: false,
      message,
      data: null,
    };
  }
}
