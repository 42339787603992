<template>
  <div>
    <HomeBanner />
    <div class="relative w-full">
      <div
        class="absolute bottom-[-19px] left-1/2 z-10 flex h-[38px] w-11/12 -translate-x-1/2 items-center rounded-xl bg-white shadow-xl md:w-7/12 lg:bottom-[-30px] lg:h-[60px] lg:w-6/12"
      >
        <DropdownCity class="text-xs" :show-flag="false" />
        <div class="my-2 h-[70%] w-[1px] bg-gray-light"></div>
        <SearchSuggestion class="w-full">
          <template #input-view="{ handler }">
            <form
              id="search-input"
              class="mx-auto"
              action=""
              @submit.prevent="handler.submitHandler"
            >
              <div class="flex items-center justify-center">
                <div
                  class="flex w-full flex-grow rounded-full bg-white text-black"
                >
                  <div class="ml-2 flex items-center">
                    <div>
                      <IcSearch class="h-6 w-6 text-[#DCDCDC]" />
                    </div>
                  </div>
                  <input
                    id="homepage-banner-search"
                    v-model="querySearch"
                    type="search"
                    autocomplete="off"
                    :placeholder="t('searchRestaurantPlaceHolder')"
                    :disabled="false"
                    :readonly="false"
                    class="h-7 flex-grow truncate rounded-full px-2 text-sm font-medium outline-none md:flex-grow md:text-base"
                    @input="handler.inputHandler"
                    @focus="
                      handler.focusHandler();
                      showRecommendation();
                    "
                    @focusout="hideRecommendation"
                  />
                </div>
              </div>
            </form>
          </template>
        </SearchSuggestion>
      </div>
      <div
        v-if="isShowRecommendation"
        id="recommendation-search"
        class="absolute left-1/2 top-9 z-10 h-min w-11/12 -translate-x-1/2 rounded-lg border border-[#f6f6f6] bg-white px-2 py-2 text-left text-black shadow-xl md:w-7/12 lg:w-6/12 lg:px-6 lg:py-4"
        tabindex="99"
        @mouseover="isRecommendationActive = true"
        @mouseleave="isRecommendationActive = false"
        @focusout="onFocusOutRecommendation"
      >
        <SimpleRecommendation />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { defineAsyncComponent, ref, watch } from "#imports";
import HomeBanner from "~/partial/home/HomeBanner.vue";
import DropdownCity from "~/components/common/DropdownCity.vue";
import IcSearch from "~icons/ic/baseline-search";

const SearchSuggestion = defineAsyncComponent(
  () => import("~/section/search_suggestion/SearchSuggestion.vue")
);

const SimpleRecommendation = defineAsyncComponent(
  () => import("~/section/search_suggestion/SimpleRecommendation.vue")
);

const { t } = useI18n();
const isShowRecommendation = ref<Boolean>(false);
const isRecommendationActive = ref<Boolean>(false);
const querySearch = ref<String>("");

function hideRecommendation() {
  if (!isRecommendationActive.value) {
    isShowRecommendation.value = false;
  }
}

function showRecommendation() {
  if (querySearch.value.length > 0) {
    isShowRecommendation.value = false;
  } else {
    isShowRecommendation.value = true;
  }
}

function onFocusOutRecommendation() {
  isRecommendationActive.value = false;
  isShowRecommendation.value = false;
}

watch(querySearch, (value) => {
  if (value.length > 0) {
    isShowRecommendation.value = false;
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "searchRestaurantPlaceHolder": "Search Restaurant, Cuisine, Location"
  },
  "th": {
    "searchRestaurantPlaceHolder": "ชื่อร้านอาหาร, ประเภท, สถานที่"
  }
}
</i18n>
