<template>
  <section ref="refBudgetRecomendation" name="budget recomendation">
    <div class="relative overflow-x-clip">
      <div
        v-if="dataBudget.length"
        class="mb-6 flex items-center pl-3 lg:mb-10"
      >
        <HomeSectionTitle :title="`${t('whatsYourBudget')}`" />
      </div>
      <Suspense>
        <template #default>
          <template v-if="loading">
            <BudgetRecomendationSkeleton />
          </template>
          <template v-else>
            <BudgetRecomendationSlider :data-budgets="dataBudget" />
          </template>
        </template>
        <template #fallback>
          <BudgetRecomendationSkeleton />
        </template>
      </Suspense>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useIntersectionObserver } from "@vueuse/core";
import getBudgetRecomendation from "~/api/home_section/getBudgetRecomendation";
import BudgetRecomendationSkeleton from "~/section/home/BudgetRecomendation/BudgetRecomendationSkeleton.vue";
import BudgetRecomendationSlider from "~/section/home/BudgetRecomendation/BudgetRecomendationSlider.vue";
import HomeSectionTitle from "~/components/home/HomeSectionTitle.vue";

const refBudgetRecomendation = ref(null);
const targetIsVisible = ref(false);

const { stop } = useIntersectionObserver(
  refBudgetRecomendation,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting) {
      targetIsVisible.value = isIntersecting;
      stop();
    }
  }
);
const { t } = useI18n({
  inheritLocale: true,
});
const dataBudget: Ref<any[]> = ref([]);

const loading = ref(false);
const fetchDataBudgetRecomendation = async () => {
  try {
    loading.value = true;
    const { data } = await getBudgetRecomendation();
    if (Array.isArray(data) && data.length) {
      dataBudget.value = data;
    }
  } catch (error) {
    dataBudget.value = [];
  } finally {
    loading.value = false;
  }
};
watch(targetIsVisible, (val) => {
  val && fetchDataBudgetRecomendation();
});
</script>

<style lang="scss">
.container-budget::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
<i18n>
  {
    "en": {
      "whatsYourBudget": "What's Your Budget?",
    },
    "th": {
      "whatsYourBudget": "คุณเตรียบงบไว้เท่าไหร่",
    }
  }
  </i18n>
