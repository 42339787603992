<template>
  <div
    class="relative flex min-h-[209px] justify-center md:justify-between md:gap-4"
  >
    <div class="skeleton-loader left-[-20px]">
      <div class="skeleton-card"></div>
    </div>
    <div class="skeleton-loader z-[1] scale-110 overflow-hidden md:scale-100">
      <div class="skeleton-card"></div>
    </div>
    <div class="skeleton-loader right-[-20px]">
      <div class="skeleton-card"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton-loader {
  @apply absolute h-[209px] w-[209px] md:static md:h-full md:w-full;
}

.skeleton-card {
  @apply h-full w-full rounded-xl bg-gray-300 shadow-sm;
}
</style>
