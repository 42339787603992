<template>
  <div class="recommended-tag-slider relative">
    <div v-if="isSwiperLoaded">
      <component
        :is="swiperComponent.main.value"
        class="swiper !pl-4"
        :slides-per-view="'auto'"
        @slide-change="onSlideChange"
      >
        <component
          :is="swiperComponent.slide.value"
          v-for="tag in tags.slice(0, 8)"
          :key="tag.id"
          class="mr-3 whitespace-nowrap rounded bg-[#f6f6f6] px-2 py-1 text-sm text-black lg:text-xl"
          :class="isLoading ? '!h-5 !w-3/12 lg:!w-1/5' : '!min-h-[30px] !w-fit'"
          @click="emits('on-tag-clicked', tag.id)"
        >
          <span v-if="!isLoading">{{ tag.attributes?.title }}</span>
        </component>
      </component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, type PropType } from "vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "~/assets/css/swiper.scss";
import useSwiper from "~/composables/useSwiper";
import { type RecommendedTagData } from "~/services/common/getRecommendedTag";

defineProps({
  tags: {
    type: Array as PropType<RecommendedTagData>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits<{
  (e: "on-tag-clicked", data: string): void;
}>();

const isReachEnd = ref(false);
const isReachStart = ref(true);
const { isSwiperLoaded, loadSwiper, swiperComponent } = useSwiper();

const onSlideChange = (val: any) => {
  isReachStart.value = val.isBeginning;
  isReachEnd.value = val.isEnd;
};

await loadSwiper();

defineOptions({
  name: "RecommendedTagSlider",
});
</script>
