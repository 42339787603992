import useHttp from "~/composables/useHttp";
import {
  isString,
  isNumber,
  isBoolean,
  isArray,
  isObject,
} from "~/helpers/dataType";

interface Budget {
  min: number;
  max: number;
  currency: string;
}
interface Attributes {
  id: string | number;
  title: string;
  description: string;
  footerDescription: string;
  slug: string;
  compactRestaurant: boolean;
  totalRestaurants: number;
  groupTagId: null | string;
  branchId: null | string;
  tagId: null | string;
  sortBy: string;
  mobilePhotos: string[];
  desktopPhotos: string[];
  logo: string;
  budget: Budget;
}
interface Response {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Data {
  data: Response[];
  success: boolean;
  message: null;
}

async function fetchBudgetRecomendation(): Promise<{
  isSuccess: boolean;
  data: null | Attributes[];
  message: string;
}> {
  const defaultErrorMessage =
    "Oops, someting went wrong, failed get budget recomendation data";
  const url = "group_landing_pages/budgets.json?minor_version=3";
  try {
    const response = await useHttp({
      url,
      method: "GET",
    });

    const { data } = response.data as Data;
    if (Array.isArray(data) && data.length) {
      const rebuildData = data.map((data) => {
        const { attributes } = data;
        return {
          id: isString(data.id),
          title: isString(attributes.title),
          description: isString(attributes.description),
          footerDescription: isString(attributes.footerDescription),
          slug: isString(attributes.slug),
          compactRestaurant: isBoolean(attributes.compactRestaurant),
          totalRestaurants: isNumber(attributes.totalRestaurants),
          groupTagId: isString(attributes.groupTagId),
          branchId: isString(attributes.branchId),
          tagId: isString(attributes.tagId),
          sortBy: isString(attributes.sortBy),
          mobilePhotos: isArray(attributes.mobilePhotos),
          desktopPhotos: isArray(attributes.desktopPhotos),
          logo: isString(attributes.logo),
          budget: isObject(attributes.budget),
        };
      });
      return {
        isSuccess: true,
        data: rebuildData as Attributes[],
        message: "",
      };
    }
    return {
      isSuccess: false,
      data: [],
      message: "",
    };
  } catch (err: any) {
    return {
      isSuccess: false,
      data: [],
      message: err.message || defaultErrorMessage,
    };
  }
}

export default fetchBudgetRecomendation;
