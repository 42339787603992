<template>
  <div class="package-type-section mb-12 mt-14">
    <div
      class="mt-2 text-center text-sm font-black capitalize lg:mt-4 lg:text-xl"
      :class="
        isLoading
          ? 'mx-auto  h-3 w-5/12 bg-gray-300 text-gray-300 lg:h-6 lg:w-1/12'
          : ''
      "
    >
      {{ !isLoading ? t("packageTypeSectionTitle") : "" }}
    </div>
    <div
      class="mb-2 text-center text-xs capitalize lg:mb-6 lg:block lg:text-lg"
      :class="
        isLoading
          ? 'mx-auto  mt-2 h-3 w-7/12 bg-gray-300 text-gray-300 lg:h-6 lg:w-4/12'
          : null
      "
    >
      {{ !isLoading ? t("packageTypeSectionSubTitle") : "" }}
    </div>

    <div class="relative mt-4">
      <div class="flex justify-center">
        <div
          v-for="item in packageTypeData"
          :key="item.id"
          class="package-type-item"
          :class="isLoading ? 'is-loading' : null"
        >
          <PackageType v-bind="item" :is-loading="isLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  ref,
  type Ref,
  watch,
  defineAsyncComponent,
  toRefs,
} from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import {
  DINE_IN,
  DELIVERY,
  EXPERIENCE,
  PACKAGE_CODE_AYCE,
  PACKAGE_CODE_PP,
  PACKAGE_CODE_HAH,
  PACKAGE_CODE_XP,
} from "../../constants";
import { getServiceType } from "~/services/common/getPackageType";
import useCityStore from "~/stores/city";
import alert from "~/lib/alert";
import type {
  ServiceType,
  PackageType as PackageTypeDefine,
} from "~/types/ServiceType";

const PackageType = defineAsyncComponent(
  () => import("~/section/package_type/PackageType.vue")
);

const props = defineProps({
  homeSectionOrder: {
    type: Number,
    required: true,
  },
});

const { t } = useI18n();
const cityStore = useCityStore();
const { selectedCityId } = storeToRefs(cityStore);
const isLoading = ref(true);
const packageTypeData: Ref<PackageTypeDefine[]> = ref([]);

const buildLink = (typeCode: string) => {
  if (typeCode === DINE_IN) {
    return `/restaurants/search?city_id=${selectedCityId.value}&package_type[]=${PACKAGE_CODE_AYCE}&package_type[]=${PACKAGE_CODE_PP}&dine_in=true`;
  } else if (typeCode === DELIVERY) {
    return `/restaurants/search?city_id=${selectedCityId.value}&package_type[]=${PACKAGE_CODE_HAH}`;
  } else if (typeCode === EXPERIENCE) {
    return `/restaurants/search?city_id=${selectedCityId.value}&package_type[]=${PACKAGE_CODE_XP}`;
  }
};

const goToLink = (typeCode: string) => {
  if (typeCode === DINE_IN) {
    return `/restaurants/search?city_id=${selectedCityId.value}&package_type[]=${PACKAGE_CODE_AYCE}&package_type[]=${PACKAGE_CODE_PP}&dine_in=true`;
  } else if (typeCode === DELIVERY) {
    return `/restaurants/search?city_id=${selectedCityId.value}&package_type[]=${PACKAGE_CODE_HAH}`;
  } else if (typeCode === EXPERIENCE) {
    return `/restaurants/search?city_id=${selectedCityId.value}&package_type[]=${PACKAGE_CODE_XP}`;
  }
};

async function fetchData() {
  isLoading.value = true;
  packageTypeData.value = [];

  const { data, isSuccess, message } = await getServiceType({
    cityId: selectedCityId.value,
  });

  if (!isSuccess || !data) {
    alert.error(message);
    return;
  }

  if (data) {
    const serviceTypeData = data as ServiceType[];
    serviceTypeData.map((item: ServiceType) => {
      packageTypeData.value.push({
        id: item.id,
        name: item.attributes.name,
        icon: item.attributes.logo.url,
        typeCode: item.attributes.typeCode,
        packageTypes: item.attributes.packageTypes,
        buildLink: buildLink(item.attributes.typeCode),
        goToLink: goToLink(item.attributes.typeCode),
      });
    });

    isLoading.value = false;
  }
}

watch(selectedCityId, (newVal) => {
  fetchData();
});

onMounted(() => {
  fetchData();
});
</script>

<script lang="ts">
export default {
  name: "HomePackageType",
};
</script>

<style lang="scss" scoped>
.package-type-item {
  &:last-child {
    margin-right: 0;
  }

  @screen lg {
    margin-right: 139px;
  }

  margin-right: 28px;
}
</style>
