<template>
  <section v-if="isVoucherFeatureEnabled" class="mx-2 lg:mx-4">
    <HomeSectionTitle :title="'Hungry Hub Gift Card'" />
    <nuxt-link class="mb-8 w-full" to="/">
      <HhImage
        :src="isDesktop ? urlVoucherBanner.desktop : urlVoucherBanner.mobile"
        width="90"
        height="90"
        :use-mutator="false"
        alt="Voucher Banner"
        class="w-full object-contain"
        :sources="sources"
      />
    </nuxt-link>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import HhImage from "~/components/HhImage.vue";
import { isDesktop } from "~/helpers/screenSize";
import { rebuildAssetURL } from "~/helpers/url";
import useConfigStore from "~/stores/config";
import HomeSectionTitle from "~/components/home/HomeSectionTitle.vue";
const configStore = useConfigStore();

const { isVoucherFeatureEnabled, urlVoucherBanner } = storeToRefs(configStore);

const sources = [
  {
    source: rebuildAssetURL(urlVoucherBanner.value.desktop),
    format: "webp",
    breakpoint: {
      minWidth: 781,
    },
  },
  {
    source: rebuildAssetURL(urlVoucherBanner.value.mobile),
    format: "webp",
    breakpoint: {
      maxWidth: 780,
    },
  },
];
</script>

<style lang="scss" scoped>
.section-title {
  @apply font-black text-[#3D4245]  lg:text-xl;
}
</style>
