<template>
  <div class="mx-4">
    <div v-show="isShow" class="max-width">
      <div
        ref="observerTarget"
        class="section-title"
        :class="isLoading ? 'is-loading' : null"
      >
        <div v-if="!isLoading" class="relative">
          <HomeSectionTitle :title="'Book Again'" />
        </div>
      </div>
      <div>
        <RestaurantCardSlider
          :restaurants="showedRestaurants"
          :slide-per-view="props.slidePerView"
          :is-loading="isLoading"
          :side-image="false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useIntersectionObserver } from "@vueuse/core";
import {
  computed,
  onMounted,
  ref,
  type Ref,
  watch,
  defineAsyncComponent,
} from "vue";
import type { RestaurantCardSliderProps } from "~/types/Restaurant";
import { restaurantCardMapper } from "~/services/restaurant";
import { createDummyFeaturedRestaurant } from "~/services/restaurant/dummy";
import { getBookAgain } from "~/api/home_section/getBookAgain";
import alert from "~/lib/alert";
import HomeSectionTitle from "~/components/home/HomeSectionTitle.vue";
import { storageGetAccessToken } from "~/composables/accessToken";

const props = defineProps({
  slidePerView: {
    type: [Number, String],
    required: true,
  },
});
const observerTarget = ref(null);
const isVisible = ref(false);
const isDataLoaded = ref(false);
const isLoading = ref(true);
const isShow = ref(true);
const restaurants: Ref<RestaurantCardSliderProps["restaurants"]> = ref([]);
const restaurantsDummy: Ref<RestaurantCardSliderProps["restaurants"]> = ref([]);
const showedRestaurants = computed(() => {
  return isLoading.value ? restaurantsDummy.value : restaurants.value;
});
const RestaurantCardSlider = defineAsyncComponent(
  () => import("~/section/restaurant_card_slider/RestaurantCardSlider.vue")
);
const { stop } = useIntersectionObserver(
  observerTarget,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

async function fetchData() {
  isLoading.value = true;
  const { data, isSuccess, message } = await getBookAgain({
    accessToken: storageGetAccessToken().data,
  });
  if (!isSuccess || !data) {
    alert.error(message);
    return;
  }
  if (data) {
    restaurants.value = [];
    data.data.forEach(async (item) => {
      restaurants.value.push(await restaurantCardMapper({ restaurant: item }));
    });
    isShow.value = restaurants.value.length > 0;
    isLoading.value = false;
    isDataLoaded.value = true;
  }
}

function initDummy() {
  const slidePerView =
    typeof props.slidePerView === "string" ? 4 : props.slidePerView;
  for (let index = 0; index < slidePerView; index++) {
    const temp = createDummyFeaturedRestaurant();
    const {
      link,
      name,
      price,
      pricingType,
      cuisine,
      location,
      totalLocations,
      avgReviews,
      totalReviews,
      cover,
      acceptVoucher,
      lastBookingWasMade,
      totalCovers,
    } = temp.attributes;
    const id = `${index}-${new Date().getTime()}`;
    restaurantsDummy.value.push({
      cardToRender: "default",
      isLoading: isLoading.value,
      id,
      link,
      image: {
        src: cover?.original || "",
        useMutator: true,
      },
      name,
      price: price?.amount || 0,
      primaryCuisine: cuisine || "",
      primaryLocation: location || "",
      totalLocations: totalLocations || 0,
      reviewsCount: totalReviews || 0,
      reviewsScore: avgReviews || 0,
      totalCovers: totalCovers || 0,
      branchId: 0,
      diningStyle: "",
    });
  }
}

initDummy();
onMounted(() => {
  watch(isVisible, (newVal) => {
    if (newVal === true && isDataLoaded.value === false) {
      fetchData();
    }
  });
});
</script>
<script lang="ts">
export default {
  name: "BookAgain",
};
</script>

<style scoped>
.section-title {
  @apply my-2 text-left text-lg font-bold text-[#3D4245] lg:my-4;
  @screen lg {
    font-size: 24px;
    line-height: 24px;
  }
}

.section-title.is-loading {
  @apply mx-auto h-4 w-9/12 bg-gray-300 text-gray-300 lg:w-6/12;
}
</style>
