import { useReport } from "~/composables/useReport";
import { getServiceType as fetchServiceType } from "~/api/common/serviceType";
import type { ServiceType } from "~/types/ServiceType";

type CityId = {
  cityId: string | number;
};

export async function getServiceType(id: CityId): Promise<{
  isSuccess: boolean;
  data: null | ServiceType[];
  message: string;
}> {
  const cityId = id?.cityId;
  const { isSuccess, data, message } = await fetchServiceType(cityId);

  if (!isSuccess) {
    useReport({
      level: "error",
      message,
    });
    return {
      isSuccess: false,
      message,
      data: null,
    };
  }
  return {
    isSuccess: true,
    message: "",
    data,
  };
}
