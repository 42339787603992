import { isEmpty } from "lodash-es";
import qs from "qs";
import humps from "humps";
import useHttp from "~/composables/useHttp";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";
import type { APILink } from "~/types/Common";
import type { Restaurant } from "~/types/Restaurant";
export interface BookAgainResponse {
  data: Restaurant[];
  links: APILink;
  success: boolean;
  message: null;
}
export interface BloggerReviews {
  data: {
    id: string;
    type: string;
  }[];
}

export interface Relationships {
  pictures: BloggerReviews;
  restaurantPackages: BloggerReviews;
  lastReviews: BloggerReviews;
  bloggerReviews: BloggerReviews;
}

export async function getBookAgain({
  accessToken,
}: {
  accessToken: string;
}): Promise<{
  isSuccess: boolean;
  data: null | BookAgainResponse;
  message: string;
}> {
  const defaultErrorMessage = `${DEFAULT_ERROR_MESSAGE}, failed get book again data`;
  const queryString = {
    accessToken,
  };
  const parseQueryString = qs.stringify(humps.decamelizeKeys(queryString), {
    encode: false,
  });
  const finalUrl = `/user_restaurant_histories?${parseQueryString}`;

  const { data, error } = await useHttp({
    url: finalUrl,
    method: "GET",
  });

  const responseData = data as BookAgainResponse;
  if (isEmpty(responseData)) {
    return {
      isSuccess: false,
      message: error.message || defaultErrorMessage,
      data: null,
    };
  }

  if (!responseData.success) {
    return {
      isSuccess: false,
      message: responseData.message || defaultErrorMessage,
      data: null,
    };
  }
  return {
    isSuccess: true,
    message: "",
    data: responseData,
  };
}
