<template>
  <component
    v-if="isSwiperLoaded"
    class="swiper-budget-container"
    :is="swiperComponent.main.value"
    v-bind="optionSwiper"
    :modules="[swiperModule.pagination.value]"
  >
    <component
      :is="swiperComponent.slide.value"
      v-for="(data, index) in dataBudgets"
      :key="index"
    >
      <BudgetRecomendationCard
        :desktopPhotos="data.desktopPhotos"
        :title="data.title"
        :mobilePhotos="data.mobilePhotos"
        :budget="data.budget"
      />
    </component>
    <div
      class="swiper-pagination custom-swiper-active-pagination !bottom-[-25px] block lg:hidden"
    ></div>
  </component>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import useSwiper from "~/composables/useSwiper";
import BudgetRecomendationCard from "~/section/home/BudgetRecomendation/BudgetRecomendationCard.vue";
interface Budget {
  min: number;
  max: number;
  currency: string;
}
interface dataBudgets {
  desktopPhotos: string[];
  title: string;
  mobilePhotos: string[];
  width?: string;
  budget: Budget;
}
interface Props {
  dataBudgets: dataBudgets[];
}
const props = defineProps<Props>();

const { loadSwiper, isSwiperLoaded, swiperComponent, swiperModule } =
  useSwiper();

const optionSwiper = {
  spaceBetween: -20,
  slidesPerView: 2,
  initialSlide: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    dynamicBullets: true,
  },
  allowTouchMove: true,
  centeredSlides: true,
  roundLengths: true,
  loop: false,
  breakpoints: {
    600: {
      slidesPerView: 3,
      spaceBetween: 20,
      allowTouchMove: true,
      pagination: false,
      centeredSlides: false,
      roundLengths: false,
      loop: false,
    },
  },
};

onMounted(() => {
  loadSwiper();
});
</script>
<style lang="scss">
.swiper-budget-container {
  &.swiper {
    @apply overflow-visible;
  }
  @apply relative;
}

.swiper-budget-container > .swiper-slide__content {
  @apply absolute top-0 lg:block;
}
.custom-swiper-active-pagination {
  .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    background: red !important;
  }
  .swiper-pagination-bullet {
    @apply h-[6px] w-[6px] scale-100 transform;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply h-[6px] w-[30px];
    border-radius: 50px;
  }
}
.swiper-budget-container {
  .swiper-slide {
    @apply flex w-full transform items-center justify-center;
    &.swiper-slide-active {
      @apply z-10 scale-110 md:scale-100;
    }
  }
}
</style>
