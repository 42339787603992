<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { rebuildAssetURL } from "~/helpers/url";
import HhImage from "~/components/HhImage.vue";
interface Budget {
  min: number;
  max: number;
  currency: string;
}
interface Props {
  desktopPhotos: string[];
  title: string;
  mobilePhotos: string[];
  width?: string;
  budget: Budget;
}
const desktopPhotosUrl = computed(() =>
  rebuildAssetURL(props.desktopPhotos[0])
);
const mobilePhotosUrl = computed(() => rebuildAssetURL(props.mobilePhotos[0]));
const props = defineProps<Props>();
const { title, width, budget } = toRefs(props);
const url = computed(() => {
  const { min, max } = budget.value;
  return {
    path: "/restaurants/search",
    queryParams: {
      min,
      max,
      price_type: "per_person",
    },
  };
});

const sources = computed(() => {
  return [
    {
      source: desktopPhotosUrl.value,
      format: "webp",
      breakpoint: {
        minWidth: 780,
      },
    },
  ];
});
</script>

<template>
  <NuxtLink
    :title="title"
    :to="{
      path: url.path,
      query: { ...url.queryParams },
    }"
  >
    <figure
      :class="width"
      class="relative flex justify-center overflow-hidden rounded-xl"
    >
      <HhImage
        class="h-[209px] w-[209px] md:h-full md:w-full"
        :sources="sources"
        :alt="title"
        :src="mobilePhotosUrl"
        :width="369"
      />
    </figure>
  </NuxtLink>
</template>
