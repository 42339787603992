<template>
  <div v-if="recommendedTags.length" class="mb-6">
    <RecommendedTagSlider
      :is-loading="isLoading"
      :tags="recommendedTags"
      @on-tag-clicked="onTagClicked"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, ref, toRefs } from "vue";
import { storeToRefs } from "pinia";
import {
  getRecommendedTag,
  type RecommendedTagData,
  type RecommendedTag,
} from "~/services/common/getRecommendedTag";
import RecommendedTagSlider from "~/section/home/RecommendedTag/RecommendedTagSlider.vue";
import useCityStore from "~/stores/city";
import alert from "~/lib/alert";

const props = defineProps({
  endPointApi: {
    type: String,
    default: "",
  },
});

const { endPointApi } = toRefs(props);

const emits = defineEmits<{
  (e: "on-tag-clicked", data: RecommendedTag): void;
}>();

const cityStore = useCityStore();
const { selectedCityId } = storeToRefs(cityStore);
const recommendedTags = ref<RecommendedTagData>([]);
const isLoading = ref(true);

async function fetchDataTag() {
  isLoading.value = true;
  const { data, success, message } = await getRecommendedTag({
    endPointApi: endPointApi.value,
    cityId: selectedCityId.value,
    pageNumber: 1,
    pageSize: 8,
  });

  if (!success || !data) {
    if (message) {
      alert.error(message);
      return;
    }
  }

  if (data) {
    recommendedTags.value = data.data;
    isLoading.value = false;
  }
}

function onTagClicked(id: string) {
  const selectedTagOption = recommendedTags.value.find(
    (item) => item.id === id
  );
  if (selectedTagOption) {
    emits("on-tag-clicked", selectedTagOption);
  }
}

watch(selectedCityId, () => {
  fetchDataTag();
});

onMounted(() => {
  fetchDataTag();
});

defineOptions({
  name: "HomeRecommendedTag",
});
</script>
