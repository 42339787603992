<template>
  <main class="relative">
    <div v-if="isMobile" class="absolute left-[15px] top-[10px] z-10">
      <div v-if="!isConfigLoading" class="h-[24px] text-white">
        <DropdownCity
          :selected-styles="'text-white border-white border-b-[1px] pb-1 pl-2 w-[110px]'"
          :padding-option="'pt-1'"
          :arrow-styles="'text-white w-[10px]'"
          :option-styles="'py-1 pl-2'"
        />
      </div>
    </div>
    <div class="relative mb-20">
      <HomeBannerView />
    </div>
    <div class="container mx-auto">
      <HomeRecommendedTag />
      <HomePackageType :home-section-order="1" />
      <NearMe
        :slide-per-view="slidePerView"
        :permission-state="permissionState || 'prompt'"
        @on-request-user-location="onRequestUserLocation"
      />
      <HomeRestaurantSlider
        :api-order="9"
        :home-section-order="2"
        :slide-per-view="slidePerView"
      />
      <HomePromotion :home-section-order="16" />
      <HomeRestaurantSlider
        :api-order="8"
        :home-section-order="3"
        :slide-per-view="isDesktop || isTablet ? 4 : 1"
        :side-image="!isDesktop && !isTablet"
      />
      <HomeRestaurantSlider
        :api-order="3"
        :home-section-order="4"
        :slide-per-view="slidePerView"
      />
      <TopCuisine
        :api-order="7"
        :home-section-order="5"
        :slide-per-view="isDesktop ? 7 : 'auto'"
      />
      <TopCuisine
        :api-order="10"
        :home-section-order="10"
        :slide-per-view="isDesktop ? 7 : 'auto'"
      />
      <div v-if="isUserSignedIn">
        <BookAgain :slide-per-view="slidePerView" />
      </div>
      <TopBrand
        :api-order="5"
        :home-section-order="11"
        :slide-per-view="isDesktop ? 7 : 3"
      />
      <HomeBudgetRecomendation v-if="isThCountry" class="my-20" />
      <HomeIconSection :slide-per-view="isDesktop ? 4 : 'auto'" />
      <HomeGiftCard />
      <HomeRestaurantSlider
        :api-order="13"
        :home-section-order="15"
        :is-more-to-explore="true"
        :slide-per-view="slidePerView"
      />
      <HomePromotion :home-section-order="16" />
      <TopCuisine
        :api-order="4"
        :home-section-order="17"
        :slide-per-view="isDesktop ? 7 : 'auto'"
      />
    </div>
  </main>
</template>

<script lang="ts" setup>
import { watch, ref } from "vue";
import "~/assets/css/index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "~/assets/css/swiper.scss";
import { storeToRefs } from "pinia";
import HomeBudgetRecomendation from "./HomeBudgetRecomendation.vue";
import HomePromotion from "./HomePromotion.vue";
import NearMe from "./near_me/NearMe.vue";
import useConfigStore from "~/stores/config";
import useUserStore from "~/stores/user";
import { onMounted, computed } from "#imports";
import HomeBannerView from "~/partial/home/HomeBannerView.vue";
import HomeGiftCard from "~/partial/home/HomeGiftCard.vue";
import TopBrand from "~/partial/home/TopBrand.vue";
import BookAgain from "~/partial/home/BookAgain.vue";
import TopCuisine from "~/partial/home/TopCuisine.vue";
import { getAllGroupLanding } from "~/services/group_landing/getAllGroupLanding";
import HomePackageType from "~/partial/home/HomePackageType.vue";
import HomeRestaurantSlider from "~/partial/home/HomeRestaurantSlider.vue";
import HomeIconSection from "~/partial/home/HomeIconSection.vue";
import HomeRecommendedTag from "~/partial/home/HomeRecommendedTag.vue";
import useClientTypeStore from "~/stores/clientType";
import useCityStore from "~/stores/city";
import { isLoading as isConfigLoading } from "~/services/common/getCities";
import DropdownCity from "~/components/common/DropdownCity.vue";
import {
  locationAccessPermission,
  askUseCurrentLocation,
} from "~/helpers/permissions";
import toast from "~/lib/alert";

const configStore = useConfigStore();
const userStore = useUserStore();
const clientTypeStrore = useClientTypeStore();
const cityStore = useCityStore();

const { isDesktop, isMobile, isTablet } = storeToRefs(clientTypeStrore);
const { selectedCityId, isThCountry } = storeToRefs(cityStore);
const { lat, lng } = storeToRefs(userStore);
const slidePerView = isDesktop.value ? 4 : "auto";
await configStore.getConfig();

const permissionState = ref<PermissionState>();

async function checkPermission() {
  const { success, data, message } = await locationAccessPermission();
  if (success && data) {
    watch(data, (val) => {
      permissionState.value = val;
      if (permissionState.value === "granted") {
        onRequestUserLocation();
      }
    });
  }
}

async function onRequestUserLocation() {
  interface Location {
    lat: number;
    lng: number;
  }

  try {
    const location = await askUseCurrentLocation({
      enableHighAccuracy: true,
    });

    if (!location) {
      return;
    }

    const locationResult = location as Location;
    lat.value = locationResult.lat;
    lng.value = locationResult.lng;
  } catch (error: any) {
    toast.error(error);
  }
}

onMounted(() => {
  getAllGroupLanding();
  checkPermission();
});

function selectCity(val: string) {
  selectedCityId.value = val;
}

const isUserSignedIn = computed(() => {
  return userStore.isUserSignedIn;
});
</script>

<script lang="ts">
export default {
  name: "HomePage",
  components: { NearMe },
};
</script>
<style lang="scss">
.custom-select-option {
  padding: 5px 14px 5px 5px;
}
.custom-select-option option {
  background-color: #fff;
  color: #000;
  font-size: 12px;
}
</style>
